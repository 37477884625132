import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app= createApp(App)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
router.beforeEach((to,from,next)=>{
    if (to.meta.title){
      document.title=to.meta.title
    }
    next()
  })