<template>
  <container-main></container-main>
</template>

<script>


import ContainerMain from "@/components/ContainerMain";
export default {
  name: 'HomeView',
  components: {ContainerMain},
}
</script>
