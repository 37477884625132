<template>
<h3>拉萨维多利亚整形美容医院-科室介绍</h3>
  <div class="text-index"><p> 拉萨维多利亚整形美容医院是西藏获准成立的整形美容医院，
    医院设置了医疗美容科、皮肤美容科、美容牙科、美容皮肤科等科室。我院将秉承"让美，更美"的核心理念，以顾客满意就是我们的追求为服务宗旨， 
    竭诚为广大求美者提供机会。客服联系电话<a href="tel:0891-6405555">0891-6405555</a>,
     在线咨询链接：<a href="https://kf7.kuaishang.cn/bs/im.htm?cas=57051___573251&amp;fi=67633">美丽咨询</a>。 
     了解更多美丽资讯，你也可以关注微信公众号：‘Lswdlyyy’。本站所有图片仅供参考，如有侵权请拨打客服电话。 </p></div>
</template>

<script>
export default {
    name:'keshi',
    

}
</script>

<style>

</style>