<!--
<template>
  <div id="container"></div>
</template>

<script>
import TMap from 'TMap'
export default {
  name: 'TenMap',
  data () {
    return {
      nowLat: 29.647503, // 纬度,91.149683
      nowLng: 91.149683, // 经度
      map: null // 地图对象
    }
  },
  created () {
    this.mapInit(this.nowLng, this.nowLat, 17)
  },
  methods: {
    /**
     * 根据传入的值渲染地图及传出经纬度和地名
     * @param lng 经度
     * @param lat 纬度
     * @param zoom 地图缩放级别
     */
    mapInit (lng, lat, zoom) {
      const _this = this
      // 在页面渲染完成后进行地图的实例化
      this.$nextTick(() => {
        _this.map = new TMap.Map(document.getElementById('container'), {
          // 地图的中心地理坐标。
          center: new TMap.LatLng(lat, lng),
          zoom: 9
        })
      })
    }
  }
}

</script>
<style>
#container{
  width: 500px;
  height: 500px;
}
</style>
 -->
 <template>
   <div class="map" id="tx_map_d"></div>
 </template>

 <script>
   import { defineComponent,nextTick,onMounted } from 'vue'
   export default defineComponent({
       name:'TenMap',
     setup(){
       onMounted(() => {
         nextTick(()=>{
           initMap()
         })
       })
       const initMap = () => {
         //定义地图中心点坐标
         var center = new TMap.LatLng(29.647503,91.149683)
         //定义map变量，调用 TMap.Map() 构造函数创建地图
         var map = new TMap.Map(document.getElementById('tx_map_d'), {
           center: center,//设置地图中心点坐标
           zoom: 18.4,   //设置地图缩放级别
           pitch: 43.5,  //设置俯仰角
           rotation: 45    //设置地图旋转角度
         });

         // 创建点聚合实例
         var markerCluster = new TMap.MarkerCluster({
           id: 'cluster',
           map: map,
           enableDefaultStyle: true, // 启用默认样式
           minimumClusterSize: 2, // 形成聚合簇的最小个数
           geometries: [{ // 点数组
             position: new TMap.LatLng(29.647503,91.149683)
           },
             {
               position: new TMap.LatLng(29.647503,91.149683)
             },
             {
               position: new TMap.LatLng(29.647503,91.149683)
             },
             {
               position: new TMap.LatLng(29.647503,91.149683)
             }
           ],
           zoomOnClick: true, // 点击簇时放大至簇内点分离
           gridSize: 60, // 聚合算法的可聚合距离
           averageCenter: false, // 每个聚和簇的中心是否应该是聚类中所有标记的平均值
           maxZoom: 10 // 采用聚合策略的最大缩放级别
         });

       }
       return {

       }
     }
   })

 </script>

 <style >
   .map{
     width: 70%;
     height: 70%;
   }
 </style>
