import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContainerBody from '@/views/ContainerBody.vue'
import Lyuan from'@/views/Lyuan.vue'
import KeShi from'@/views/KeShi'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title: '拉萨维多利亚整形美容医院【官网】'
    }

  },
  {
    path: '/container',
    name: 'container',
    component: ContainerBody,
    meta:{
      title: '拉萨维多利亚整形美容医院-医院简介'
    }
  },
  {
    path: '/laiyuan',
    name: 'laiyuan',
   component:Lyuan,
   meta:{
    title: '拉萨维多利亚整形美容医院-来院路线'
  }
  },
  {
    path: '/keshi',
    name: 'keshi',
   component:KeShi,
   meta:{
    title:'拉萨维多利亚整形美容医院-科室介绍'
   }

  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
