<template>
  <el-container>
    <el-header height="400">

     <ContainerHeader/>

    </el-header>
    <el-main>


      <router-view/>



    </el-main>
<ContainerFooter/>
  </el-container>
</template>
<script>

import ContainerHeader from '@/components/ContainerHeader.vue'

import ContainerFooter from '@/components/ContainerFooter.vue'
export default{
  name:'wdlyapp',
  components:{
    ContainerHeader,
    ContainerFooter
}

}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
a{ list-style-type: none; text-decoration-line: none;}
</style>
