<template>

 <h3>拉萨维多利亚整形美容医院-医院简介</h3>
  <div  class="text-index">
    <p>
      拉萨维多利亚整形美容医院，是西藏获准成立的专业整形美容医院，医院设置了医疗美容科、皮肤美容科、美容牙科、美容皮肤科等科室。我院将秉承"让美，更美"的核心理念，以顾客满意就是我们的追求为服务宗旨，
      竭诚为广大求美者提供机会。咨询预约电话<a href="tel:0891-6405555">0891-6405555</a>, 在线咨询链接：<a href="https://kf7.kuaishang.cn/bs/im.htm?cas=57051___573251&fi=67633">美丽咨询</a>。
      了解更多美丽资讯，你也可以关注微信公众号：‘Lswdlyyy’。
    </p>
    <p>
      拉萨维多利亚整形美容医院-附近的站点: 东郊客运站站、邮车总站站、邮车总站、老藏大、臧大、藏大站、邮车总站、市政公司站、雅鲁藏布酒店站、日光宾馆东门站、日光宾馆站、市供电所站、市农牧局站、阳城广场站、市供电所站、市畜牧局站。
     拉萨维多利亚整形美容医院附近的公交车: 10路、9路、27路、12路、17路、8路、4路、6路、11路、3路、5路、15路等。
    </p>

  </div>
  <div class="map">
    <ten-map></ten-map>
  </div>

</template>

<script>
import TenMap from "@/components/Map";
export default {
  name: 'ContainerBody',
  components: {
    TenMap

  }
}
</script>

<style>

.map{ display: flex;justify-content: center;padding: 30px}
.text-index{text-indent:2em;line-height: 2; justify-content: left;width: 100%; text-align: left;
}
</style>
