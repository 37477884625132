<template>
  <div class="logo">
    <el-image :src="logo" width="399px" height="69px"></el-image>
  </div>
  <el-menu
    :default-active="activeIndex"
    select="index"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
    background-color="#5b4dac"
    active-text-color="#ffffff"
    text-color="#ffffff"
  >
    <el-menu-item index="1">
      
      <router-link to="/">首页</router-link>
    </el-menu-item>
    <el-menu-item index="2">
     <router-link to="/container">医院简介</router-link>
    </el-menu-item>

    <el-menu-item index="3">
     <router-link to="/keshi">科室介绍</router-link>
    </el-menu-item>
    <el-menu-item index="5">
     <router-link to="/laiyuan">来院路线</router-link>
    </el-menu-item>

    <el-menu-item index="6">
      <el-link  type="primary"  :underline="false"   href="tel:0891-6405555">咨询电话</el-link>
    </el-menu-item>
    <el-menu-item index="7">
 <el-popover title="微信搜索：“拉萨维多利亚整形美容医院”" trigger="click" >
  <template #reference>微信公众号</template>
 </el-popover>
    </el-menu-item>

  
  </el-menu>
</template>

<script>
export default {
  name: "ContainerHeader",
  data() {
    return {
      logo: require("@/assets/images/logo.png"),
      		activeIndex: '1',
    };
  },
};
</script>

<style>
.logo {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-bottom: 10px;
		}

		.logo img {
			width: 248px;
		}

</style>