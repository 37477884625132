<template>
  <el-carousel :interval="5000" height="700px" arrow="always">
    <el-carousel-item v-for="url3 in banner" :key="url3">
      <el-image :src="url3" :fit="contain" loading="lazy"></el-image>
    </el-carousel-item>
  </el-carousel>
  <div class="body-text">
    <h3>拉萨维多利亚整形美容医院医院简介：</h3>
    拉萨维多利亚整形美容医院，是西藏获准成立的专业整形美容医院，医院设置了医疗美容科、皮肤美容科、美容牙科、美容皮肤科等科室。我院将秉承"让美，更美"的核心理念，以顾客满意就是我们的追求为服务宗旨，
    竭诚为广大求美者提供机会。咨询预约电话<a href="tel:0891-6405555"
      >0891-6405555</a
    >, 在线咨询链接：<a
      href="https://kf7.kuaishang.cn/bs/im.htm?cas=57051___573251&fi=67633"
      >美丽咨询</a
    >。
  </div>
   <el-row :gutter="20">
    <el-col :span="4" v-for="url in card" :key="url">
      <el-card  shadow="hover">   <el-image :src="url" :fit="contain" loading="lazy"></el-image> </el-card>
    </el-col>

  </el-row> 
 
</template>

<script>
export default {
  name: "ContainerMain",
  data() {
    return {
      banner: [
        require("@/assets/images/banner-1.jpg"),
        require("@/assets/images/banner-2.jpg"),
        require("@/assets/images/banner-3.jpg"),
        require("@/assets/images/banner-4.jpg"),
      ],
      card: [
        require("@/assets/images/m1.jpg"),
        require("@/assets/images/m2.jpg"),
						require("@/assets/images/m3.jpg"),
				require("@/assets/images/m4.jpg"),
				require("@/assets/images/m5.jpg"),
				require("@/assets/images/m6.jpg"),
      ],
    };
  },
};
</script>

<style>
.body-text { height: 150px;padding:30px;}
</style>